import React from 'react';
import { Container, Button, Grid, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import helmholtzPNG from '@package/static/helmholtz.png';
import { Doormat } from '@package/components';
import { Icon } from '@plone/volto/components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import HiERNFooter from '@package/components/Footer/HiERNFooter';
import { ErrorBoundary } from 'react-error-boundary';
import config from '@plone/volto/registry';

import printSVG from '@package/icons/print.svg';
import twitterSVG from '@package/icons/twitter.svg';
import youtubeSVG from '@package/icons/youtube.svg';
import facebookSVG from '@package/icons/facebook.svg';
import upSVG from '@plone/volto/icons/collapse-up.svg';
import juelichIcon from '@package/icons/Juelich_Blogs.png';
import rssSVG from '@plone/volto/icons/rss.svg';

function ErrorFallback({ error }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
}

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const isHiERNProject =
    config.settings.isHiERNProject === 'true' ? true : false;

  return isHiERNProject ? (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HiERNFooter />
    </ErrorBoundary>
  ) : (
    <footer id="footer">
      <div className="footer-tools">
        <Container>
          <Grid stackable columns="3">
            <Grid.Column className="print">
              <Button basic icon onClick={() => window.print()}>
                <Icon name={printSVG} size="24px" />
                Seite drucken
              </Button>
            </Grid.Column>
            <Grid.Column className="scroll-to-top">
              <AnchorLink offset="0" href="#main">
                <Button basic icon>
                  <Icon name={upSVG} size="24px" />
                  nach oben
                </Button>
              </AnchorLink>
            </Grid.Column>
            <Grid.Column className="social-media">
              <a
                aria-label="fz-juelich RSS-Feed"
                href="https://fz-juelich.de/portal/DE/Service/rss/_node.html "
              >
                <Icon name={rssSVG} size="24px" />
              </a>
              <a
                aria-label="fz-juelich Youtube"
                href="https://www.youtube.com/user/forscheninjuelich/featured "
                target="_blank"
                rel="noreferrer"
              >
                <Icon name={youtubeSVG} size="24px" />
              </a>
              <a
                aria-label="fz-juelich Twitter"
                href="https://twitter.com/fz_juelich "
                target="_blank"
                rel="noreferrer"
              >
                <Icon name={twitterSVG} size="24px" />
              </a>
              <a
                aria-label="fz-juelich Facebook"
                href="https://www.facebook.com/Forschungszentrum.Juelich"
                target="_blank"
                rel="noreferrer"
              >
                <Icon name={facebookSVG} size="24px" />
              </a>
              <a href="https://blogs.fz-juelich.de/">
                <img
                  src={juelichIcon}
                  style={{ height: '24px' }}
                  alt="Jülich Blogs"
                />
              </a>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
      <Doormat columns="6" />
      <div className="footer-bottom">
        {/* <Segment role="contentinfo" vertical padded textAlign="center"> */}
        <Segment vertical padded textAlign="center">
          <Container>
            <div className="left">
              <ul className="links">
                <li className="item">© Forschungszentrum Jülich</li>
                <li className="item">
                  <Link to="/impressum">
                    <FormattedMessage
                      id="Impressum"
                      defaultMessage="Impressum"
                    />
                  </Link>
                </li>
                <li className="item">
                  <Link to="/datenschutz">
                    <FormattedMessage
                      id="Datenschutz"
                      defaultMessage="Datenschutz"
                    />
                  </Link>
                </li>
                <li className="item">
                  <Link to="/barrierefreiheit">
                    <FormattedMessage
                      id="Barrierefreiheit"
                      defaultMessage="Barrierefreiheit"
                    />
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <a href="https://www.helmholtz.de/" className="helmholtz">
                <img src={helmholtzPNG} alt="Helmholtz-Logo" loading="lazy" />
              </a>
            </div>
          </Container>
        </Segment>
      </div>
    </footer>
  );
};

export default injectIntl(Footer);
